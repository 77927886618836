
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { TimingPlan } from '@/types/irrigation'
import { intellectIrrigationDesc } from '@/utils/config'

@Component
export default class IrrigationSmartPlanAdd extends Vue {
  // 正整数校验，小于20
  numLessTen = function (rule: object, value: string, callback: Function) {
    const reg = /^[1-9][0-9]*$/
    if (!reg.test(value) || Number(value) < 2 || Number(value) > 20) {
      return callback(new Error('请输入2到20数值'))
    } else {
      return callback()
    }
  }

  droughtRules = function (rule: object, value: string, callback: Function) {
    const reg = /^([1-9][0-9]*|[0-9])(\.\d)?$/
    if (value && !reg.test(value)) {
      return callback(new Error('请输入一位以内的小数或正整数'))
    } else if (Number(value) > 100) {
      return callback(new Error('请输入小于等于100的数字'))
    } else {
      return callback()
    }
  }

  private info: TimingPlan = {
    planName: '',
    projectAreaId: '',
    projectId: '',
    dateRange: [],

    drought: '',
    concurrentNum: '',
    keepLength: ''
  }

  private rules = {
    planName: [
      { required: true, message: '请输入计划名称', trigger: ['blur', 'change'] }
    ],
    projectAreaId: [
      { required: true, message: '请选择喷灌区域', trigger: ['blur', 'change'] }
    ],
    dateRange: [
      {
        required: true,
        message: '请选择计划时间段',
        trigger: ['blur', 'change']
      }
    ],
    drought: [
      { required: true, message: '请输入喷灌启动阈值', trigger: ['blur'] },
      { validator: this.droughtRules, trigger: ['blur', 'change'] }
    ],

    concurrentNum: [
      {
        required: true,
        message: '请输入电磁阀一次同时开启的个数，范围1个到3个',
        trigger: ['blur', 'change']
      }
    ],
    keepLength: [
      {
        required: true,
        message: '请输入电磁阀开启时长，时长范围2分钟到20分钟',
        trigger: ['blur', 'change']
      },
      { validator: this.numLessTen, trigger: ['blur', 'change'] }
    ]
  }

  private areaList = []
  private submitShow = false
  private proposalThreshold = ''

  get intellectIrrigationDesc () {
    return intellectIrrigationDesc
  }

  created () {
    if (this.$route.params.id) {
      this.getPlanDetail(this.$route.params.id)
    } else {
      this.getArea()
    }
  }

  getPlanDetail (id: string) {
    this.$axios
      .get(this.$apis.irrigation.irrigationPlanDetail, {
        planId: id
      })
      .then((res) => {
        this.info = {
          planId: res.planId,
          planName: res.planName,
          projectAreaId: res.projectAreaId,
          projectId: res.projectId,
          dateRange: [res.startDate, res.endDate],

          drought: res.drought,
          concurrentNum: res.concurrentNum,
          keepLength: res.keepLength
        }
        this.getArea()
        this.getDrought()
      })
  }

  getArea () {
    this.$axios
      .get(this.$apis.project.selectProjectAreaByList, {
        projectId: this.$route.query.projectId,
        isIdimArea: '1',
        notAllArea: '1'
      })
      .then((res) => {
        this.areaList = res.list || []
      })
  }

  areaChange () {
    this.getDrought()
  }

  getDrought () {
    this.$axios
      .get(this.$apis.irrigation.selectIrrigationProposal, {
        projectAreaId: this.info.projectAreaId
      })
      .then((res) => {
        console.log('res: ', res)
        this.proposalThreshold = res || ''
      })
  }

  onSubmit () {
    ;(this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const info = {
          planId: this.info.planId || null,
          planName: this.info.planName,
          projectAreaId: this.info.projectAreaId,
          projectId: this.$route.query.projectId,
          startDate: this.info.dateRange[0],
          endDate: this.info.dateRange[1],
          concurrentNum: this.info.concurrentNum,
          keepLength: this.info.keepLength,
          drought: this.info.drought
        }
        this.$axios
          .post(this.$apis.irrigation.irrigationPlanAdd, {
            ...info,
            planType: '2'
          })
          .then(() => {
            this.$message({ message: '保存成功', type: 'success' })
            this.goBack()
          })
          .finally(() => {
            this.submitShow = false
          })
      }
    })
  }

  goBack () {
    this.$router.push({
      path: '/irrigationmodelsetting/detail/smartplan',
      query: { projectId: this.$route.query.projectId }
    })
  }
}
